import { io } from 'socket.io-client';


let socket =  io({
    autoConnect: false,
    reconnectionAttempts: 30,
    reconnectionDelay: 3000,
    reconnectionDelayMax: 15000,
    // origins: "*",
    withCredentials: true,
    transports: ['websocket', 'polling'],
  });

export const initializeSocket = (gstate) => {
    let socketRoom = gstate.socket_room;
    // Cookies.set('socketroom', gstate.socket_room, { path: '/', expires: 1 }); 
  if (true) { //!socket
    // socket = io({
    //   reconnectionAttempts: 30,
    //   reconnectionDelay: 3000,
    //   reconnectionDelayMax: 15000,
    //   origins: "*"
    // });

    if (gstate.user_info_json?.loggedin === true){
        socket.connect();
        socket.on("connect", function() {
            // console.log("Attempting to join room", socketRoom);
            socket.emit('join', { "socket_room": socketRoom });
            console.log("Attempting to join user_id room and this", socketRoom);
            socket.emit('request_unacknowledged_messages', { "socket_room": socketRoom });
        });
    } else {
        console.log("Not connecting because unregistered user")
    }

    socket.on("reconnect_attempt",(attempt)=>{
        console.log("Reconnecting for the " + attempt + "th time")
    })
    socket.on("reconnect",(attempt) => {
        console.log("successfully reconnected at " + attempt + "th time")
    })
    socket.on("disconnect",(reason) =>{
        console.log("disconnected because " + reason)
    })
  }

  return socket;
};

export const getSocket = () => socket;