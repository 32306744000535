<template>
    <RegisterLogin />
    <div id="app">
      <router-view :key="componentKey"></router-view>
    </div>
  </template>
  
  <script>
  import { useI18n } from 'vue-i18n'
  import { useUserStore } from './stores/user'
  import { onMounted, reactive, computed, watch, watchEffect, provide, ref, onErrorCaptured } from 'vue'
  // import { io } from 'socket.io-client'
  // import {file_one_line_fs_desc, file_one_line_ms_desc} from './constants_gptagent.js';
  import { getTranslatedConstants } from './constants_gptagent.js';
  
  import axios from 'axios';
  import {loadScript, gen_random_string} from './loadScript.js';
  import { useRouter, useRoute } from 'vue-router'
  import { initializeSocket } from './socketService.js'
  import Cookies from 'js-cookie';
  import RegisterLogin from './components/RegisterLogin.vue';
  export default {
    name: 'App',
    components: {
      RegisterLogin
    },
    setup() {
      // const socket = io({reconnectionAttempts: 30, reconnectionDelay:3000, reconnectionDelayMax: 15000,origins:"*"})
      // const socket = getSocket();
      // onErrorCaptured((error, instance, info) => {
      //   console.error('Global Error Captured:');
      //   console.error('Error:', error);
      //   console.error('Component:', instance);
      //   console.error('Info:', info);
      //   console.error('Stack trace:', error.stack);
      //   return false;
      // });
      const { t, locale } = useI18n();
      
      const userStore = useUserStore();
      const default_model_select_options = [
          // { text: "ChatGPT (Auto)", value: "chatgpt_model" },
          { text: "Claude-3.7 (Sonnet)", value: "claude35_sonnet"},
          { text: "DeepSeek R1 671B", value: "deepseek_r1_nb"},
          { text: "GPT-4o (Omni)", value: "gpt4o_model"},
          { text: "GPT-4o (Mini)", value: "gpt4_om"},
          { text: "GPT-4 Turbo", value: "gpt4_model" },
          { text: "Claude-3 (Haiku)", value: "claude3_haiku"},
          { text: "DeepL Translate",value:"deepl"},
          { text: "Google Translate",value:"google"},
      ];
      function make_dropdown(in_or_out) {
        let locale = gstate?.user_info_json?.locale || "en";
        let prefix="";
        let output = [];
        if (["google","deepl","gpttranslate","genexercise"].includes(gstate?.function_select)) {
            if (gstate.src_lang != 'nil'){
                if (gstate?.model_select==='google') {
                    prefix = 'gt_'}
                else if (gstate?.model_select==="deepl") {
                    prefix="dl_"
                }
                else if (gstate?.function_select==="genexercise") {
                    prefix="dl_"
                    //not very ideal, but a cheap way to generate auto & other languages
                }
                else if (["gpttranslate"].includes(gstate?.function_select)) {
                    prefix="gpt_"
                }
                else if (gstate?.function_select==='cc') {
                    prefix = 'cc_'
                }
                else{ prefix = 'support_'
                    }
                let df_mask = prefix + in_or_out // gt_in, support_out etc
                let smalldf = gstate.languages_df.filter((entry)=> entry[df_mask] ===1) //JSON.parse(gstate.languages_df).filter((entry)=> entry[df_mask] ===1) 
                //filter out languages that have no gt_in, support_out etc
                // console.log("prefix",prefix,"df_mask",df_mask, "smalldf",smalldf,"gstate.languages_df",gstate.languages_df)
                if (in_or_out == 'out'){
                    smalldf = smalldf.filter((entry)=> entry["abbr"] !=="zh-both");
                    let smalldftext = JSON.stringify(smalldf)
                    let filters = [entry => entry['abbr'] !=gstate.src_lang, entry=> entry['abbr'] !='nil']
                    smalldf=JSON.parse(smalldftext).filter((entry)=>filters.every(fn=>fn(entry)));
                    if (gstate.src_lang && gstate.src_lang.includes('zh')) {
                        smalldftext = JSON.stringify(smalldf)
                        smalldf=JSON.parse(smalldftext).filter((entry)=>entry['abbr'] !='zh-both')
                    }
                }
                locale = locale + '_name';
                let obj = null;
                for (let index in smalldf) {
                    obj = smalldf[index];
                    // output = output + `<option value="${obj['abbr']}">${obj[locale]}</option>`;
                    output.push({"value":obj['abbr'],"text":obj[locale]})
                }
            }
            else{
                // output = '<option value="nil">{{$t("No Translation")}}</option>'
                output.push({"value":"nil","text":t("No Translation")})
            }
          }
          return output;
      };
      const gstate = reactive({
        is_lg: false, //viewport was lg
        is_vsm: false,
        is_vertical: false,
        vision_models: ["gpt4_model","gpt4o_model", "gpt4_om", "claude3_haiku","claude3_opus","claude3_sonnet","claude35_sonnet"],
        counter: 0,
        socket_room: 'A1B2C3D4E5F6G7H8',
        machine_name: '',
        machine_port: 0,
        page_title: "Welcome to GPTBowl",
        disable_fs_n_control: false,
        vtfxyz: "",
        ai_mode:"text", //"file", "image"
        expand_right_panel: true,
        mounted_components : [],
        used_token: 0,
        using_vtf: false,
        uf: {},
        ti: {},
        func: {},
        k: {
          "reasoning_models": {
            "deepseek_r1_nb": {"begin":"<think>", "end":"</think>"},
          },
        },
        rlstate: {
          "redirect": "/overview",
        },
        fsvstate: {
          error: "",
          share_msg: "",
          custrw_task_in: "",
        },
        overviewstate:{
          elevate: null,
          counter: 0, //use only for triggering refresh 
        },
        mlstate: {
          "global_chat_id": -1,
          "global_filekey": -1,
          "show":false,
          "sharelink_store": "",
          "tikey": -1,
          "sharing_override": "text", //text, file, image //decouple sharing from gstate.ai_state, in case you need to have conflicting components together
        },
        ustate: {
          "pond_version": 1.00,
          "umode":"gptassist", //file2file
        },
        ostate: {},
        fstate: {
          show_all: true,
          fmode: 'pond', // 'pond' use pond for uploadpond, 'main' use main for filemanager, 'ti' for text to image
        },
        tistate: {
          input_image_ufkey: -1,
          show_fm: false, // show File Manager
          prompt:'',
        },
        ptstate : {
          mode: 'text',
          search_term: '',
          selected_key: null,
          key: null
        },
        state: {
          show_system: false,
          user_system: '', //system prompt
          user_system_is_disabled: false,

        }, //askai
        mapping: {}, //store outfile's ufkey -> srcfile's ufkey mapping; remember to remove mapping on done or failed, to prevent cross contamination
        os_color: false, //turn on color for option selectors?
        function_select: "gpttranslate",
        model_select: "deepl",
        ocr: "auto",
        src_selector_options: computed(()=> {
                                    let output = [];
                                    if (gstate && gstate.user_info_json && gstate.languages_df) {
                                        output =  make_dropdown("in")
                                    }
                                    return output
                                }),
        trg_selector_options: computed(()=> {
                                    let output = [];
                                    if (gstate && gstate.user_info_json && gstate.languages_df) {
                                        output= make_dropdown("out")
                                    }
                                    return output
                                }),
        src_lang: "auto",
        trg_lang: "en",
        rephrase_imperfection: 1,
        rephrase_vocab:"default",
        rephrase_tone:"business",
        rephrase_limit:null,
        gx_prompt: "",
        gx_trg_lang: "auto",
        gx_difficulty:"0",
        gx_num_questions : "10",
        gx_num_choices :"auto",
        custrw_bg_in: '',
        custrw_task_in: '',
        custex_bg_in: '',
        custex_task_in: '',
        summary_user_has_set: 'pct',
        summary_pct: 25,
        summary_word: 400,
        models:computed(() => { //models = array of possible model_select, model_select is then translated to use_model in backend
            let models = [];
            if (gstate.function_select === "compare") {
                models = ["chatgpt_model","gpt4_model",];
            } else if (gstate.function_select === "compare_opus_gpt4") {
                models = ["claude3_opus","gpt4_model"];
            } else if (gstate.function_select === "ndeepseekr1") {
                models = ["deepseek_r1_nb"];
            }  else if (gstate.function_select === "ndeepseekv3") {
                models = ["deepseek_v3_0324_nb"];
            }  else if (["google","deepl","claude35_sonnet","claude3_opus","claude3_haiku"].includes(gstate.model_select) || gstate.model_select.includes("gpt4") || gstate.model_select.includes("chatgpt")) {
                models = [gstate.model_select];
            }
            else {
                // console.log("computed property of models should not be empty or everything would fail!")
                // models = [state.function_select]
                if (gstate.function_select) console.error(`Models not set for fs=${gstate.function_select} & ms=${gstate.model_select}!`);
            }
            if (models.length === 0) console.error("models should not be empty!")
            return models
        }),
        model_select_options: default_model_select_options,
      })
      watch(()=> gstate.page_title, (newVal, oldVal) => {
        document.title = newVal;
      })
      gstate.func["extractReasoning"] = function extractReasoning(content, begin, end) {
        const output = {
          COT: "",
          core: content
        };
  
        const escBegin = begin.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const escEnd = end.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const pattern = new RegExp(`${escBegin}.*?${escEnd}`, 's');
        
        const match = content.match(pattern);
        
        if (match) {
          output.COT = match[0];
          output.core = content.replace(pattern, '').trim();
        }
        
        return output;
      }
      gstate.func["flush_user_setting"] = async function flush_user_setting() {
        //confirm first
        if (!confirm(t("Restore default user settings, such as output length and warnings? This will NOT delete your chats"))) return;
        const response = await fetch('/_flush_user_setting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          
          if (data.status === 'success') {
            gstate.user_setting = {};
            if (gstate.user_info_json?.user_setting) {
              gstate.user_info_json.user_setting = {};
            }
          }
      }
      gstate.func["request_unacknowledged_messages"] = function request_unacknowledged_messages (socket) {
        console.log("I am called!")
        socket.emit('request_unacknowledged_messages', { "socket_room": gstate.socket_room });
      }
      gstate.func["open_rl_modal"] = function open_rl_modal() {
        
        if (gstate?.user_info_json?.loggedin === true){
          console.error("RL modal should not open when trying to log in?")
          return; 
        }
        else{
          const loginRegisterModal = new bootstrap.Modal(document.getElementById('loginRegisterModal'))
          loginRegisterModal.show()
        }
      }
      gstate.func["getMimeType"] = function getMimeType(filename) {
        const mimeTypes = {
          'pdf': 'application/pdf',
          'jpg': 'image/jpeg',
          'jpeg': 'image/jpeg',
          'png': 'image/png',
          'gif': 'image/gif',
          'txt': 'text/plain',
          'html': 'text/html',
          'css': 'text/css',
          'js': 'application/javascript',
        };
        const fileExtension = filename.split('.').pop().toLowerCase();
        return mimeTypes[fileExtension] || 'application/octet-stream';
      }
      gstate.func["show_toast"] = function show_toast(message) {
          var toastEl = document.getElementById('liveToast');
          var toast = new bootstrap.Toast(toastEl);
          toastEl.querySelector('.toast-body').textContent = message;
          toast.show();
          setTimeout(function(){ toast.hide(); }, 4500);
      }

      gstate.func["count_word"] = function count_word(input) {
          function isCjk(char) {
              const cjkRanges = [
                  [0x4E00, 0x9FFF],  // CJK Unified Ideographs
                  [0x3400, 0x4DBF],  // CJK Unified Ideographs Extension A
                  [0x20000, 0x2EBEF],  // CJK Unified Ideographs Extensions B-F
                  [0x2F800, 0x2FA1F],  // CJK Compatibility Ideographs Supplement
                  [0xAC00, 0xD7AF],  // Hangul Syllables
                  [0x1100, 0x11FF],  // Hangul Jamo
                  [0x3130, 0x318F],  // Hangul Compatibility Jamo
                  [0xA960, 0xA97F],  // Hangul Jamo Extended-A
                  [0xD7B0, 0xD7FF],  // Hangul Jamo Extended-B
                  [0x3040, 0x30FF],  // Hiragana and Katakana
                  [0x31F0, 0x31FF],  // Katakana Phonetic Extensions
                  [0xFF66, 0xFF9F],  // Halfwidth Katakana
                  [0x1B000, 0x1B16F],  // Kana Extended and Small Kana Extension
              ];

              const code = char.charCodeAt(0);
              return cjkRanges.some(([start, end]) => code >= start && code <= end);
          }

          if (input === "") return 0;

          let wordCount = 0;
          let nonCjkBuffer = "";

          for (let char of input) {
              if (isCjk(char)) {
                  // Process any accumulated non-CJK characters
                  if (nonCjkBuffer.trim()) {
                      wordCount += nonCjkBuffer.trim().split(/\s+/).length;
                      nonCjkBuffer = "";
                  }
                  wordCount++; // Count each CJK character as a word
              } else {
                  nonCjkBuffer += char;
              }
          }

          // Process any remaining non-CJK characters
          if (nonCjkBuffer.trim()) {
              wordCount += nonCjkBuffer.trim().split(/\s+/).length;
          }

          return wordCount;
      }
      gstate.func["draw_attention"] = function draw_attention(element, message) {
          if (!element) {
            console.log("element not found when attempting to draw attention", element);
            return;
          }
          element.scrollIntoView({behavior: "smooth"});
          element.focus();

          element.classList.add('alert-button');
          const textbox = document.createElement('label');
          textbox.classList.add("form-check-label", "alert-button") //.add('textboxtwo', 'text-dark', 'bg-white');
          textbox.innerText = message;
          // element.appendChild(textbox);
          element.parentElement.insertBefore(textbox, element);
          // textbox.style.display = 'block';
          setTimeout(() => {
              element.classList.remove('alert-button');
              if (textbox) {
                  // textbox.style.display = 'none'; // hide the textbox
                  textbox.remove();
              }
          }, 5000);
          setTimeout(() => {
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 10);
      }
      const router = useRouter()
      const route = useRoute()
      const componentKey = ref(0)
      const navigateTo = (path, refresh_overview_counter = false) => {
        const targetRoute = router.resolve(path)
        console.log("path", path, refresh_overview_counter)
        // console.log("targetcomponent", targetRoute.matched[0].components.default.name, "source", route.matched[0].components.default.name, targetRoute.matched[0].components.default.name===route.matched[0].components.default.name)
        if (targetRoute.meta?.login_required === true && gstate?.user_info_json?.loggedin !== true) {
          // console.log("Attempting to access login required link and server side check not triggered")
          // window.location.href = path; //use this method to properly redirect after login
          console.log("Waiting for login")
          gstate.rlstate.redirect = path;
          gstate.func.open_rl_modal();
        } else {
          if (["Askai","FileProc"].includes(route.meta?.parentComponentName)) { // there is a bug in Askai component that prevents me from navigating away - no time to fix it yet
            window.location.href = path; // must reload completely to allow get_dict_from_list to work correctly. sometimes it seems there are two state.model_msg? one is empty?
          }
          else if (["Askai","FileProc"].includes(targetRoute.meta?.parentComponentName)) { // there is a bug in Askai component that prevents me from navigating away - no time to fix it yet
            window.location.href = path; // must reload completely to allow get_dict_from_list to work correctly. sometimes it seems there are two state.model_msg? one is empty?
          }
          if (path === route.path || (targetRoute.meta?.parentComponentName !== null && route.meta?.parentComponentName && targetRoute.meta?.parentComponentName)) { //targetRoute.matched[0].components.default === route.matched[0].components.default
            componentKey.value++
            // console.log("adding component key")
            router.push(path)
          } else {
            router.push(path)
          }
          if (gstate.using_vtf===true && gstate.func && gstate.func["vtf"]) {
            setTimeout(()=>{gstate.func["vtf"]();},50);
          }
          if (refresh_overview_counter === true) {
            setTimeout(()=>{gstate.overviewstate.counter++},1000);
          }
        }
        if (targetRoute.meta?.parentComponentName === null || route.meta?.parentComponentName === null) {
          console.log("Forgot to set parentComponentName", targetRoute, route)
        }
        // if (targetRoute.meta?.parentComponentName === "Askai" && gstate?.mounted_components?.includes("Askai")) {
        
      }
      
      const no_setting_fs = ["grammar"] //function_selects that do not have settings such as {fs}_selector
      const file_always_combine_fs = ["genexercise"] //fs that always call start_combine
      const file_chat_linked_fs = ["genexercise"]
      const vision_model_select = ["gpt4_model","gpt4o_model", "claude3_haiku","claude3_opus","claude3_sonnet","claude35_sonnet"]
      const pay_only_use_models = ["claude3_opus"]
      const track_changes_function = ["grammar","rephrase"];
      const switch_fs = ["deepl", "google", "gpttranslate"]; //function_selects that require switching
      const model_value_to_name = {
          "gpt4_model": "GPT-4 Turbo",
          "gpt4o_model": "GPT-4o Omni",
          "gpt4_om": "GPT-4o Mini",
          "chatgpt_model": "ChatGPT (Auto)",
          "claude35_sonnet": "Claude-3.7 (Sonnet)",
          "claude3_opus": "Claude-3 (Opus)",
          "claude3_haiku": "Claude-3 (Haiku)",
          "deepseek_r1_nb": t("DeepSeek R1 671B"),
          "deepseek_v3_0324_nb": t("DeepSeek V3 0324"),
          "google": "Google Translate",
          "deepl": "DeepL Translate",
      }
      
      const model_context = computed (()=> {
          let max_token = 8123;
          switch(gstate.model_select) {
              case "gpt4_model":
                  max_token = 128000;
                  break;
              case "gpt4o_model":
              case "gpt4_om":
                  max_token = 128000;
                  break;
              case "chatgpt_model":
                  max_token = 16000;
                  break;
              case "claude35_sonnet":
              case "claude3_sonnet":
              case "claude3_haiku":
              case "claude3_opus":
                  max_token = 200000;
                  break;
              default:
                  max_token = 7456;
          }
          return max_token;
      });
      const model_output = computed (()=> {
          let max_token = 4096;
          switch(gstate.model_select) {
              default:
                  max_token = 4096;
          }
          return max_token;
      });
      const remaining_context_length = computed (() => {
          let max_token_cap_on_user = 9999999;
          if (gstate.user_info_json && gstate.user_info_json.limit_context) {
              max_token_cap_on_user = gstate.user_info_json.limit_context;
          }
          let remaining = Math.min(max_token_cap_on_user, model_context.value) - gstate.used_token;
          return remaining;
      });
      const remaining_output = computed (() => {
          let remaining = Math.min(remaining_context_length.value, model_output.value);
          console.log("remaining_output_length",remaining, "remaining_context_length",remaining_context_length.value, "model_output",model_output.value);
          return remaining;
      })
      const cc = { //cannot store computed properties to gstate then provide it
        "remaining_context_length": remaining_context_length,
        "remaining_output": remaining_output,
        "model_context": model_context,
        "model_output": model_output,
        "model_value_to_name": model_value_to_name,
      }
      async function saveUserSetting(kvPairs) {
        // "Input look like this : const settingsToSave = [
        //   ['show_advanced', true],
        //   ['show_tutorial', false],
        // ];"
        try {
          const response = await fetch('/_save_user_setting', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ kv_pair: kvPairs }),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          
          if (data.status === 'success') {
            if (!(gstate.hasOwnProperty('user_setting')) || gstate.user_setting === null) {
              gstate.user_setting = {};
            } 
            kvPairs.forEach(([key, value1]) => {
              gstate.user_setting[key] = value1;
            });
            console.log('User settings saved successfully');
          } else {
            console.error('Failed to save user settings:', data.msg);
          }

          return data;
        } catch (error) {
          console.error('Error saving user settings:', error);
          return { status: 'failed', msg: 'An error occurred while saving user settings' };
        }
      }
      function split_filename_and_ext(filename) {
        const lastDotIndex = filename.lastIndexOf('.');
        let fileName, fileExt;
        if (lastDotIndex === -1) {
            fileName = filename;
            fileExt = 'unknown_ext';
        } else {
            fileName = filename.slice(0, lastDotIndex);
            fileExt = filename.slice(lastDotIndex + 1).toLowerCase();
        }

        return [fileName, fileExt];
      }

      function dest2text(label, labeldict) {
        let ext_to_description = {
          "pdf": "PDF",
          "docx": "Word",
          "pptx": "PowerPoint",
          "txt": t("Text"),
          "htm": "HTML",
          "html": "HTML",
          "zip": "ZIP",
          "xlsx": "Excel",
        }
        // front, ext = split_filename_and_ext(dest.filename);
        const [front, ext] = split_filename_and_ext(labeldict.filename);
        let prefix = t('Download ');
        let midfix = "";
        let afterfix = ""
        let extfix = "File";
        if (ext_to_description.hasOwnProperty(ext)) {
          extfix = ext_to_description[ext];
        }
        // if (["zip","txt"].includes(label)) {
        //   extfix = ext_to_description[label];
        // }
        if (label.includes("compare")) {
          afterfix = t("(Track Changes)");
        } else if (["merge","merged"].includes(label)) {
          afterfix = t("(Merged)");
        } else if (label === "source") {
          afterfix = t("(Source)");
        }
        
        if (midfix !== "" ) midfix = " " + midfix + " ";
        if (afterfix !== "" ) afterfix = " " + afterfix;
        
        let out = prefix + midfix + extfix + afterfix;
        return out;
      }
      function setCookie(cname, cvalue, exdays=99999) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/" + ";SameSite=Lax";
      }
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    function reading_share() {
        return (window.location.href.includes("/x/") || window.location.href.includes("/innerx/"))
    }
    function request_to_brand() {
      let outjson = {};
      if (window.location.href.includes("fastlaw.cc"))
          {outjson = {"email":"support@fastlaw.cc", "domain_name":"fastlaw.cc","brand_name":'{{_("Fast Law")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://fastlaw.cc . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@fastlaw.cc)")}}'}}
      else if (window.location.href.includes("kuaifa.ai"))
          {outjson = {"email":"support@kuaifa.ai", "domain_name":"kuaifa.ai","brand_name":'{{_("Kuai Fa")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://kuaifa.ai . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@kuaifa.ai)")}}'}}
      else if (window.location.href.includes("gptbowl"))
          {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      else
      {outjson = {"email":"support@gptbowl.com", "domain_name":"gptbowl.com","brand_name":'{{_("GPT Bowl")}}',"promo_msg":'{{_("Try GPT-4, ChatGPT, document translation and OCR service for free on https://www.gptbowl.com . Securely translate and process your documents with our on-premise servers - no Internet connection required(sales@gptbowl.com)")}}'}}
      return outjson
    }
    function getUuidFromUrl() {
        const url = new URL(window.location.href);
        const parts = url.pathname.split('/').filter(part => part !== '');
        if (parts.length >= 2 && (parts[0] === 'x' || parts[0] === 'y' || parts[0] === 'z')) {
            return parts[1];
        }
        // console.error("Error getting uuid",parts)
        return null;
    }

    function fs_to_model_select_options(fs, abd, preferred_model="") {
      console.log("called this fn",fs,abd,preferred_model)
      let model_select_options = default_model_select_options;

      if(fs === "ngpt4") {
          // model_select_options = default_model_select_options.filter((option) => option.value.includes("gpt4"));
          model_select_options = [{text:"GPT-4 Turbo",value:"gpt4_model"}];
      } else if (fs === "ngpt4o") {
          model_select_options = [{text:"GPT-4o (Omni)",value:"gpt4o_model"}];
      } else if (fs === "ngpt4om") {
          model_select_options = [{text:"GPT-4o Mini",value:"gpt4_om"}];
      } 
      else if (fs === "nsonnet") {
          model_select_options = [{text:"Claude-3.7 (Sonnet)",value:"claude35_sonnet"}];
      } else if (fs === "ndeepseekr1") {
          const value = "deepseek_r1_nb"
          model_select_options = [{text:"DeepSeek R1 671B",value:value}];
      } else if (fs === "ndeepseekv3") {
          const value = "deepseek_v3_0324_nb"
          model_select_options = [{text:"DeepSeek V3 0324",value:value}];
      }else if (fs === "nopus") {
          model_select_options = [{text:"Claude-3 (Opus)",value:"claude3_opus"}];
      } else if (fs === "nhaiku") {
          model_select_options = [{text:"Claude-3 (Haiku)",value:"claude3_haiku"}];
      }
      else if (fs === "nchatgpt") {
          model_select_options = default_model_select_options.filter((option) => option.value.includes("chatgpt"));
      }
      else if (fs === "compare") {
          model_select_options = [{text:"ChatGPT+GPT4",value:"compare"}];
      }
      else if (fs === "compare_opus_gpt4") {
          model_select_options = [{text:"Claude-3 (Opus)+GPT4",value:"compare"}];
      }
      else if (fs === "google") {
          model_select_options = [{text:"Google Translate",value:"google"}];
      }
      else if (fs === "deepl") {
          model_select_options = [{text:"DeepL Translate",value:"deepl"}];
      } 
      else if (fs === "ti") {
          model_select_options = [{text:"Normal Image Model",value:"f1d"}];
      } else if (fs === "ii") {
          model_select_options = [{text:"Pro Image Model",value:"f11p"}];
      }
      else {
          model_select_options = default_model_select_options; //*LOOKIE BELOW
      };
      if (!abd.hasOwnProperty(fs)) {
          console.error("Invalid function_select/Forgot to implement excel entry for",fs,abd)
      } else {
          if (abd[fs]["preferred_models"].length !== 0) {
              //*LOOKIE BELOW
              model_select_options = abd[fs]["preferred_models"].map(modelKey => ({
                  text: model_value_to_name[modelKey], //!!!! have you named model_value
                  value: modelKey
              }));
              if (gstate.ai_mode === "file") {
                  console.log("Expunging chatgpt from model_select_options because tc bad");
                  model_select_options = model_select_options.filter((option) => !["chatgpt_model", "deepseek_r1_nb", "deepseek_v3_0324_nb"].includes(option.value));
                  // model_select_options = model_select_options.filter((option) => !option.value.includes("claude"));
              }
              if (gstate.user_info_json && !gstate.user_info_json.is_paying_user) {
                  model_select_options = model_select_options.filter((option) => !pay_only_use_models.includes(option.value));
              }
          } 
          let oldModelSelect = gstate.model_select;
          let is_valid_model_selection = model_select_options.some(option => option.value === oldModelSelect);
          if (!is_valid_model_selection) {
              console.log("Invalid model select options, reverting to default. gstate.model_select was", gstate.model_select, "going to change to",model_select_options[0].value)
              gstate.model_select = model_select_options[0].value;
          };

      }
      if (preferred_model !== "") {
          // rearrange preferred_model to the front
          const preferredIndex = model_select_options.findIndex(option => option.value === preferred_model);
          if (preferredIndex !== -1) {
              const preferredOption = model_select_options.splice(preferredIndex, 1)[0];
              model_select_options.unshift(preferredOption);
          }
      }
      return model_select_options;
      }
      gstate.domain_json = request_to_brand();
      
      watch(() => gstate.src_lang, (newValue) => {
            if (["google","deepl","gpttranslate","genexercise"].includes(gstate?.function_select)) {
                let old_src_select = gstate.src_lang;
                let is_valid_src_selection = gstate.src_selector_options.some(option => option.value === old_src_select);
                if (!is_valid_src_selection) {
                    gstate.src_lang = gstate.src_selector_options[0].value;
                };
                let old_trg_select = gstate.trg_lang;
                let is_valid_trg_selection = gstate.trg_selector_options.some(option => option.value === old_trg_select);
                if (!is_valid_trg_selection) {
                    gstate.trg_lang = gstate.trg_selector_options[0].value;
                }
            };
        });
      // const preloadComponents = (path) => {
      //   if (path.startsWith('/o') || path === '/overview') {
      //     import('./components/Overview.vue');
      //   }
      //   else if (['/t', '/v', '/innerx'].some(p => path.startsWith(p))) {
      //     console.log("Starting Askai preloading");
      //     import('./components/Askai.vue');
      //     console.log("Finished Askai preloading");
      //   }
      //   else if (path.startsWith('/f')) {
      //     import('./components/FileProc.vue');
      //   }
      //   else if (path.startsWith('/x/')) {
      //     import('./components/Askai.vue');
      //   }
      //   else if (path.startsWith('/y/')) {
      //     import('./components/FileSharedViewer.vue');
      //   }
      //   else if (path.startsWith('/addcredit')) {
      //     import('./components/Addcredit.vue');
      //   }
      //   else if (path === '/fileviewer') {
      //     import('./components/FileViewer.vue');
      //   }
      // };

      onMounted(async () => {
        console.log("Mounting App")
        gstate.socket_room=gen_random_string(16);
        // Cookies.set('socketroom', gstate.socket_room, { path: '/', expires: 1 }); 
        Cookies.set('socket_room', gstate.socket_room, { 
          path: '/', 
          expires: 1, 
          sameSite: 'Lax',
          secure: window.location.protocol === 'https:'
        });
        function vtf (){
          const url = new URL(window.location.href);
          const parts = url.pathname.split('/').filter(part => part !== '');
          let fs = '';
          let ms = '';
          let vtf = '';
          if (parts.length >= 1) vtf = parts[0];
          if (parts.length >= 2) fs = parts[1];
          if (parts.length >= 3) ms = parts[2];
          fs = fs.split("#")[0];
          fs = fs.split("?")[0];

          if (fs !== '' && fs !== null && !["x", "y", "z", "innerx"].includes(vtf)) {
              setTimeout(() => {
                console.log("changing fs",fs)
                  gstate.function_select = fs;
                  if (ms !== '' && ms !== null) {
                      gstate.model_select = ms;
                  }
              }, 0);
          }
          gstate.vtfxyz = vtf;
        }

        gstate.func["vtf"] = vtf;
        vtf();

        function set_proper_model_select(gstate_fs, prev_run=0) {
          let abd = gstate?.user_info_json?.gptagent_dict;
            // console.log("abd here",abd, gstate_fs)
            if (abd && gstate_fs && abd[gstate_fs]?.hasOwnProperty("preferred_models")) {
              const preferredModel = abd[gstate_fs]["preferred_models"].find(modelKey => 
                gstate.model_select_options.some(option => option.value === modelKey)
              );
              
              // always return the first element of preferred_models from gstate_fs
              // if (preferredModel) {
              //     console.log("applying preferred model", preferredModel);
              //     gstate.model_select = preferredModel;
              // }
            } else {
              console.error("gptagent_dict not found in user_info_json, could be because setting not loaded")
              if (prev_run === 0) {
                setTimeout(() => {
                  set_proper_model_select(gstate_fs, 1);
                }, 2000);
              }
            }
        }
        function fetch_user_info_n_share_uuid () {
          let share_uuid = getUuidFromUrl(); //
          userStore.fetchUserInfo({share_uuid: share_uuid}).then(async () => {
            console.log("User info fetched");
            if (userStore.responseData) {
              gstate.user_info_json = userStore.responseData.user_info_json;
              initializeSocket(gstate);
              
              gstate.languages_df = userStore.responseData.languages_df;
              gstate.languages_df = JSON.parse(gstate.languages_df);
              gstate.user_setting = userStore.responseData.user_setting;
              if (!gstate.user_setting) {
                gstate.user_setting = {};
              }
              // if (!gstate.user_setting.hasOwnProperty("show_advanced")) {
              //   gstate.user_setting.show_advanced = false;
              // }
              // if (!gstate.user_setting.hasOwnProperty("show_advanced_pond")) {
              //   gstate.user_setting.show_advanced_pond = false;
              // }
              // if (!gstate.user_setting.hasOwnProperty("show_advanced_tic")) {
              //   gstate.user_setting.show_advanced_tic = false;
              // }
              ["show_advanced", "show_advanced_pond", "show_advanced_tic" ].forEach((key) => {
                if (!gstate.user_setting.hasOwnProperty(key)) {
                  gstate.user_setting[key] = false;
                }
              });
              if (!gstate.user_setting.hasOwnProperty("show_system")) {
                gstate.user_setting.show_system = false;
              }
              if (!gstate.user_setting.hasOwnProperty("display_dashed")) {
                gstate.user_setting.display_dashed = false;
              }
              locale.value = userStore.responseData.locale; //this is how frontend locale is set
              gstate.counter +=1;
              if (userStore.responseData.hasOwnProperty("shared_chat_json")) {
                gstate.shared_chat_json = userStore.responseData.shared_chat_json;
              }
              gstate.user_info_json["gptagent_dict"] = JSON.parse(gstate.user_info_json["gptagent_dict"]);
              console.log("populating model_select_options")
              gstate.model_select_options = computed(()=> {
                  return fs_to_model_select_options(gstate.function_select, gstate?.user_info_json?.gptagent_dict);
              })
            }
            if (reading_share()) {
                
                const uuid = getUuidFromUrl();
                try {
                    const response = await axios.get(`/_chatshare/${uuid}`);
                    console.log('Share data:', response.data);
                    gstate.chatshare = response.data;
                } catch (error) {
                    console.error('Error fetching share data:', error);
                    // Handle the error (e.g., show an error message to the user)
                }
            }
          });
        }
        gstate.func["fetch_user_info_n_share_uuid"] = fetch_user_info_n_share_uuid;
        gstate.func["split_filename_and_ext"] = split_filename_and_ext;
        gstate.func["fs_to_model_select_options"] = fs_to_model_select_options;
        function replaceHtmlTags(input) {
            const commonTags = [
                'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'svg', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr',
                'component', 'transition', 'transition-group', 'keep-alive', 'slot', 'template',
                'fragment',
                'ng-container', 'ng-content', 'ng-template',
            ].join('|');
            const tagPattern = new RegExp(`</?(?:${commonTags})(?:\\s[^>]*)?>`,'gi');
            return input.replace(tagPattern, function(match) {
                return '〈' + match.slice(1, -1) + '〉';
            });
        }
        gstate.func["replaceHtmlTags"]= replaceHtmlTags;
        function reverseHtmlTagReplacement(input) {
            const commonTags = [
                'a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'svg', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr',
                'component', 'transition', 'transition-group', 'keep-alive', 'slot', 'template',
                'fragment',
                'ng-container', 'ng-content', 'ng-template',
                // '[a-z]+-[a-z]+'
            ].join('|');

            const tagPattern = new RegExp(`〈/?(?:${commonTags})(?:\\s[^〉]*)?〉`, 'gi');

            return input.replace(tagPattern, function(match) {
                return '<' + match.slice(1, -1) + '>';
            });
        }
        gstate.func["reverseHtmlTagReplacement"]= reverseHtmlTagReplacement;
        function copy_conversation_or_code(element_ids=[], mode="", state) {
            var currentChatElements = [];
            let classes_to_skip = ["code-header", "top-control", "highlighted-border-center-box","codename","codebutton","icon-button", "q_or_a_storage"];
            //add 
            //if (state.show_thinking === false) { // state is not passed to this function!!
            //    classes_to_skip.push("thinking");
            //}
            const ids_to_skip = [];
            element_ids.forEach(function(element_id) {
                var element = document.querySelector("#"+element_id);
                if (element) {
                    currentChatElements.push(element);
                } else {
                    console.error("Element not found with the given id: " + element_id);
                }
            });

            function filter_children(element) {
                var children = element.querySelectorAll("*");
                Array.prototype.forEach.call(children, function(child) {
                    if (child.classList) {

                        for (var i = 0; i < classes_to_skip.length; i++) {
                            // console.log("child.classList",child.classList,"classes_to_skip",classes_to_skip[i],child.classList.contains(classes_to_skip[i]))
                            if (child.classList.contains(classes_to_skip[i])) {
                                child.remove();
                            }
                        }
                    }
                    if (child.id) {
                        for (var i = 0; i < ids_to_skip.length; i++) {
                            if (child.id === ids_to_skip[i]) {
                                child.remove();
                            }
                        }
                    }
                });
                return element;
            }
            function applyInlineStyles(element) {
                var children = element.querySelectorAll("*");
                // element = filter_children(element);
                var relevantStyles = ["font-family", "font-size", "font-weight", "color", "background-color", "text-decoration","width","height",
                                        "top","left","bottom","right","max-width","max-height",
                                        "box-sizing","flex","display","flex-wrap"];

                Array.prototype.forEach.call(children, function(child) {
                    var cssStyle = window.getComputedStyle(child);
                    for (var i = 0; i < relevantStyles.length; i++) {
                        var styleName = relevantStyles[i];
                        var styleValue = cssStyle.getPropertyValue(styleName);
                        child.style[styleName] = styleValue;
                    }
                });

                return element;
            }
            if (currentChatElements.length > 0) {
                var combinedHTML = "";
                var combinedText = "";
                currentChatElements.forEach(function(currentChatElement) {
                    var clonedChatElement = currentChatElement.cloneNode(true);
                    document.querySelector("#hidden_modal").appendChild(clonedChatElement);
                    clonedChatElement = filter_children(clonedChatElement);
                    combinedText += clonedChatElement.innerText + "\n";
                    var styledChatElement = applyInlineStyles(clonedChatElement);
                    combinedHTML += styledChatElement.innerHTML;
                    document.querySelector("#hidden_modal").removeChild(clonedChatElement);
                    combinedText = gstate.func.reverseHtmlTagReplacement(combinedText);
                    // combinedHTML = gstate.func.reverseHtmlTagReplacement(combinedHTML); //if you reverse, it will just get rendered, and you still don't get < or >
                });

                if (window.ClipboardItem && mode !== "text") {
                    var blob = new Blob([combinedHTML], {type: 'text/html'});
                    console.log("blob",blob,blob.text())
                    navigator.clipboard.write([new ClipboardItem({'text/html': blob})])
                    .then(() => {
                        showToast("Copied Formatted Text and Image, Can only be pasted into Microsoft Word");
                    })
                    .catch(err => {
                        console.error("Could not copy text because: ", err);
                        showToast("Could not copy text because: " + err);
                    });
                } else {
                    // var combinedText = "";
                    // currentChatElements.forEach(function(currentChatElement) {

                    //     combinedText += currentChatElement.innerText + "\n";
                    //     //do not try to remove special class or id, because if removed it will leave empty spaces in innerText
                    // });
                    navigator.clipboard.writeText(combinedText.trim())
                    .then(() => {
                        showToast("Copied Plaintext");
                    })
                    .catch(err => {
                        console.error("Could not copy text because: ", err);
                        showToast("Could not copy text because: " + err);
                    });
                }
                } else {
                    showToast("None of the targeted elements exists");
                }
        }
        gstate.func["copy_conversation_or_code"]= copy_conversation_or_code;
        function separate_system_user_input (content) {
            if (!content || typeof content !== 'string') {
              console.log("escaped here?")
                return content;
            }
            const userInputPattern = /<user_input>([\s\S]*?)<\/user_input>/;
            const systemPromptPattern = /<system_prompt>([\s\S]*?)<\/system_prompt>/;
            const systemPattern = /<system>([\s\S]*?)<\/system>/;
            let output_in_json = 0;
            let outjson = {"user_input": content, "system": ""};  
            const match = content.match(userInputPattern);
              if (match && match[1]) {
                output_in_json += 1;
              }
            const match2 = content.match(systemPromptPattern);
              if (match2 && match2[1]) {
                // output_in_json += 1;
                console.log("ignoring proprietary system prompt, will not be extracted")
              }
            const match3 = content.match(systemPattern);
              if (match3 && match3[1]) {
                output_in_json += 1;
              }
            if (output_in_json === 2) { //need to ensure both PATTERNS are present
              outjson["user_input"] = match[1];
              outjson["system"] = match3[1];
            } else if (output_in_json === 1 && match2 && match2[1]) {
              outjson["user_input"] = match[1];
            }
            return outjson;
        }
        gstate.func["separate_system_user_input"]= separate_system_user_input;
        function showToast(message) {
            var toastEl = document.getElementById('liveToast');
            var toast = new bootstrap.Toast(toastEl);
            toastEl.querySelector('.toast-body').textContent = message;
            toast.show();
            setTimeout(function(){ toast.hide(); }, 4500);
        }
        gstate.func["showToast"]= showToast;
        fetch_user_info_n_share_uuid(); //need to run again for any page that is prone to have share_uuid

        //REMEMBER App.vue run only once and does not get triggered on navigateTo, only gets triggered if href.location change
        watch(() => gstate.function_select, (newValue) => {
            set_proper_model_select(newValue, 0);
        });
        // const scriptUrls = [
        //   // "/static/assets/js/headroom.min.js",
        //   // "/static/assets/js/pixel.js",
        //   // "/static/assets/vendor/bootstrap/dist/js/bootstrap.min.js",
        //   // "/static/assets/js/corejsbundle332.js",
        // ];
        // const promises = scriptUrls.map(url => loadScript(url));
        // try {
        //     await Promise.all(promises);
        //     console.log('All main scripts loaded successfully');
        // } catch (error) {
        //     console.error('Failed to load external assets:', error);
        // }
        console.log("Finishing Mounting App")
        // preloadComponents(route.path);
      })
      const configs = {
        "spec_attributes": ["ocr","src_lang","trg_lang","function_select","model_select","rephrase_imperfection", "rephrase_vocab", "rephrase_tone", "rephrase_limit", "gx_prompt", "gx_trg_lang", "gx_difficulty", "gx_num_questions", "gx_num_choices", "custrw_bg_in", "custrw_task_in", "custex_bg_in", "custex_task_in", "summary_user_has_set", "summary_pct", "summary_word"],
        list_of_fs_do_track_changes : ["rephrase", "grammar", "compare"],
        list_of_fs_require_multiline2text : ["summarize"],
      }
      provide('getTranslatedConstants',getTranslatedConstants)
      provide('gstate', gstate)
      provide('t', t)
      provide('locale', locale)
      provide('configs', configs)
      // provide('socket', socket)
      provide('setCookie', setCookie)
      provide('getCookie', getCookie)
      provide('reading_share', reading_share)
      provide('getUuidFromUrl', getUuidFromUrl)
      provide('saveUserSetting', saveUserSetting)
      provide('navigateTo', navigateTo)
      provide('dest2text', dest2text)
      provide('cc', cc)
      return { t, locale, userStore, gstate, setCookie, getCookie,saveUserSetting, navigateTo, componentKey} //, socket
    }
  }
  </script>
<style>
.alert-button {
    animation: breathing 3s infinite;
    border-color: crimson;
    box-shadow: 0 0 5px crimson;
}
.pb-lg-max {
  padding-bottom: 40rem !important; 
}

.min-h-screen {
  min-height: 100vh;
}
</style>