// translatedConstants.js
import { useI18n } from 'vue-i18n';
import { ref, reactive, computed } from 'vue';
export function getTranslatedConstants(t, gstate) {
  // const { t } = useI18n();
  const k = {
    "counter": gstate.counter,
  "desc_req_ms": [
        "deepl",
        "google",
        "gpttranslate",
        "grammar",
        "summarize",
        "rephrase",
        "legal",
        "medical",
        "allergy",
        "brainstorming",
        "midjourney",
        "research_topic",
        "interview_preparation",
        "screenplay",
        "novel",
        "fitness",
        "investment_financial",
        "email",
        "travel",
        "custrw",
        "custex",
        "genexercise"
  ],
  "text_one_line_fs_desc" :  {
        "grammar": t('Correct grammatical errors with '),
        "nchatgpt": t('Ask') + ' ChatGPT 3.5',
        "ngpt4": t('Ask') + ' GPT-4 Turbo',
        "ngpt4o": t('Ask') + ' GPT-4o',
        "ndeepseekr1": t('Ask') + ' DeepSeek R1 671B',
        "ndeepseekv3": t('Ask') + ' DeepSeek v3 0324',
        "nhaiku": t('Ask') + ' Claude-3 (Haiku)',
        "nsonnet": t('Ask') + ' Claude-3.7 (Sonnet)',
        "nopus": t('Ask') + ' Claude-3 (Opus)',
        "compare": t('Compare GPT-4 and ChatGPT'),
        "compare_opus_gpt4": t('Compare Opus and GPT-4'),
        "deepl": t('Translate text with') + ' ',
        "google": t('Translate text with') + ' ',
        "gpttranslate": t('Translate text with') + '',
        "summarize": t('Write a summary with') + ' ',
        "rephrase": t('Rephrase content with') + '',
        "legal": t('Ask legal advice with') + ' ',
        "medical": t('Ask medical or dental questions with') + ' ',
        "allergy": t('Check for potential allergens with') + ' ',
        "brainstorming": t('Generate innovative ideas with') + ' ',
        "midjourney": t('Provide tailored prompts for Midjourney with') + ' ',
        "research_topic": t('Provide in-depth research with') + ' ',
        "interview_preparation": t('Interview coaching and preparation with') + ' ',
        "screenplay": t('Write screenplays with') + ' ',
        "novel": t('Write novel with') + ' ',
        "fitness": t('Give personalized fitness guidance with') + ' ',
        "investment_financial": t('Give financial and investment insights with')  + ' ',
        "email": t('Create professional emails/letters with') + ' ',
        "travel": t('Plan travel itineraries with') + ' ',
    },
  "file_one_line_fs_desc" : {
        "grammar": t('Correct grammatical errors in file with')+ " ",
        "conversion": t('Convert file format')+ " ",
        "deepl": t('Translate file with')+ " ",
        "google": t('Translate file with')+ " ",
        "gpttranslate": t('Translate files with')+ " ",
        "summarize": t('Create summary from file with') + " ",
        "rephrase": t('Rephrase style and tone in files with') + " ",
        "custrw": t('Rewrite File using your own instructions with') + " ",
        "custex": t('Extract info from file with') + " ",
        "genexercise": t('Create or clone exercises with') + " ",
    },
  "file_start_btn" : {
        "grammar": t('Correct Grammar Now'),
        "conversion": t('Convert Format Now'),
        "deepl": t('Translate Now'),
        "google": t('Translate Now'),
        "gpttranslate": t('Translate Now'),
        "summarize": t('Summarize Now'),
        "rephrase": t('Rephrase Now'),
        "custrw": t('Rewrite Now'),
        "custex": t('Extract Info Now'),
        "genexercise": t('Create Exercise Now'),
    },
  "ms_desc" : {
        "deepl": t('DeepL Translation'),
        "google": t('Google Translation'),
        "gpt4_model": t('GPT-4 Turbo'),
        "gpt4o_model": t('GPT-4o (Omni)'),
        "claude35_sonnet": t('Claude-3.7 (Sonnet)'),
        "claude35_haiku": t('Claude-3.5 (Haiku)'),
        "claude3_opus": t('Claude-3 (Opus)'),
        "claude3_haiku": t('Claude-3 (Haiku)'),
        "deepseek_r1_nb": "DeepSeek R1 671B",
        "deepseek_v3_0324_nb": "DeepSeek V3 0324",
    },
  "features_dict" : { //copied from gptagent.xlsx.  the excel is still useful for storing settings etc. but this is to facilitate translation
    "grammar": {
      "title_en": t("Fix Grammar"),
      "long_en": t("Automatically correct grammatical errors, useful for students and professionals submitting their work. Changes made by AI are highlighted in red.")
    },
    "nchatgpt": {
      "title_en": t("Ask ChatGPT"),
      "long_en": t("Ask ChatGPT version 3.5, the pioneer of modern AI developed by OpenAI, it is frequently updated with recent knowledge about the world.  Cheap to use and fast to respond, it is still useful for simpler tasks")
    },
    "ngpt4": {
      "title_en": t("Ask GPT-4 Turbo"),
      "long_en": t("Ask GPT-4 Turbo, the much improved version of ChatGPT from OpenAI.  It accepts longer question and has better knowledge. It has been used as a benchmark for all other AI to measure against until Opus arrived")
    },
    "ngpt4o": {
      "title_en": t("Ask GPT-4o Omni"),
      "long_en": t("Ask GPT-4o, the latest flagship model from OpenAI.  'o' stands for omni and this model is meant to be faster and can accept videos. It has been praised for more human-like answers but some prefer GPT-4 Turbo for more logical tasks such as writing computer codes")
    },
    "ndeepseekr1": {
      "title_en": t("Ask DeepSeek R1 671B"),
      "long_en": t("Ask DeepSeek R1, the best reasoning model today. Your data will be securely sent to servers run by an European company listed on Nasdaq, USA")
    },
    "ndeepseekv3": {
      "title_en": t("Ask DeepSeek v3 0324"),
      "long_en": t("Ask DeepSeek v3, an open weight model that rivals GPT-4o and Claude Sonnet, except it does not understand images.  Your data will be securely sent to servers run by an European company listed on Nasdaq, USA")
    },
    "nhaiku": {
      "title_en": t("Ask Claude-3 (Haiku)"),
      "long_en": t("Ask Claude-3 (Haiku), a cheap and fast model designed by Anthropic to compete against ChatGPT.  It is great for casual, everyday question that does not require complex or logical analysis.  Its support for images and 200,000 context length make it a great tool to prototype ideas at a low cost, before moving on to Claude 3.7 Sonnet for production use.")
    },
    "ngpt4om": {
      "title_en": t("Ask GPT-4o Mini"),
      "long_en": t("GPT-4o Mini is a 'good enough' model released by OpenAI to tackle common tasks like translation and basic document processing. It is cheap, fast and can output a lot of text at once.")
    },
    "nsonnet": {
      "title_en": t("Ask Claude-3.7 (Sonnet)"),
      "long_en": t("Ask Claude-3.7 (Sonnet), the best model for coding and generating human-like responses.  It understands images and provide short, direct answers, which cannot be done by DeepSeek R1")
    },
    "nopus": {
      "title_en": t("Ask Claude-3 (Opus)"),
      "long_en": t("Ask Claude-3 (Opus), a model developed by Anthropic. It decisively beats GPT-4 in many tasks and is set to be the new standard for all AI to measure up.  While quality is better, Opus is significantly more expensive than GPT-4.")
    },
    "compare": {
      "title_en": t("Compare GPT-4 w/ChatGPT"),
      "long_en": t("Compare and see how GPT-4 beats ChatGPT")
    },
    "compare_opus_gpt4": {
      "title_en": t("Compare GPT-4 w/Opus"),
      "long_en": t("Compare and see how Opus drastically improves upon GPT-4")
    },
    "conversion": {
      "title_en": t("Convert Document Format"),
      "long_en": t("Simplifies the conversion of documents between different formats. Accept (PDF, Word, PowerPoint, Images) and Output (Word or PowerPoint).  Perform Optical Character Recognition (OCR) automatically.  Lower cost and better performance than converting on your own.")
    },
    "deepl": {
      "title_en": t("DeepL Translate"),
      "long_en": t("DeepL specialises in translation and has been rated as number one AI translators for many years. Offers a rapid, cost-efficient translation service renowned for linguistic accuracy, suitable for translating texts quickly while maintaining high quality.")
    },
    "google": {
      "title_en": t("Google Translate"),
      "long_en": t("Provides a quick, affordable translation tool for translating text instantly, the same Google Translate you have used and trusted.")
    },
    "gpttranslate": {
      "title_en": t("AI Translation"),
      "long_en": t("Leverages sophisticated language models for contextually aware translations, offering nuanced understanding ideal for complex or nuanced texts requiring deeper cultural sensitivity such as novel, drama, historical texts, humour. Slower and more expensive than translating with Google or DeepL")
    },
    "summarize": {
      "title_en": t("Write Summary"),
      "long_en": t("Distills long articles into brief, digestible summaries, useful for filtering out irrelevant material and for casual studying.")
    },
    "rephrase": {
      "title_en": t("Rephrase Tone & Vocabulary"),
      "long_en": t("Adjusts the tone and vocabulary of texts to suit the intended audience, enhancing the clarity, appropriateness, and impact of communication in various contexts. Alternatively, twist the article into opposing, critical tone and see how it may be rebutted.")
    },
    "legal": {
      "title_en": t("Ask Legal/Regulatory"),
      "long_en": t("Gives preliminary advice on legal matters, serving as an initial consultation before seeking professional legal counsel, beneficial for early-stage legal inquiries.")
    },
    "medical": {
      "title_en": t("Ask Medical/Dental"),
      "long_en": t("Provides general medical information as a starting point before professional healthcare consultations, useful for gaining preliminary health-related insights. Can submit images of physical condition for more accurate response.")
    },
    "allergy": {
      "title_en": t("Ask Product/Service allergen"),
      "long_en": t("Allows users to check for potential allergens in products or services, we recommend sending a picture of  the product or service to help AI better understand.")
    },
    "brainstorming": {
      "title_en": t("Brainstorm New Ideas"),
      "long_en": t("Generates innovative ideas for various projects, aiding creativity and brainstorming, especially useful for overcoming creative blocks or initiating new projects.")
    },
    "midjourney": {
      "title_en": t("Write prompts for \"Midjourney\""),
      "long_en": t("Provide tailored prompts for \"Midjourney\" based on past successful prompts to help get the result ASAP.")
    },
    "research_topic": {
      "title_en": t("Provide deep research"),
      "long_en": t("Conducts in-depth research on complex topics, an invaluable asset for students, professionals, or anyone needing detailed information and comprehensive study.")
    },
    "interview_preparation": {
      "title_en": t("Interview Preparation"),
      "long_en": t("Get coached on your interview by AI, simulate real-world questions that you may face. Anticipate any deficiency in your skillset or CV, gain confidence through repeated trials")
    },
    "screenplay": {
      "title_en": t("Write Screenplay"),
      "long_en": t("Assists in crafting compelling screenplays with AI assistance, streamlining the creative writing process for screenwriters at any skill level.")
    },
    "novel": {
      "title_en": t("Write Novel"),
      "long_en": t("Generate a novel from your topic - result can be formulaic at first but you can fine-tune it to suit your imagination")
    },
    "fitness": {
      "title_en": t("Fitness Goal"),
      "long_en": t("Let AI guide you on setting and tracking personalized fitness goals based on established best practice.  AI will assess your specific needs such as health and age and give personalised recommendation.")
    },
    "investment_financial": {
      "title_en": t("Ask Investment/Financial"),
      "long_en": t("Provide insight to your financial/investment questions based on established, proven strategies.  Avoid costly pitfalls and assess opportunities on an objective and fair basis.")
    },
    "email": {
      "title_en": t("Draft Email/Letter"),
      "long_en": t("Create professional and clear emails/letters. AI will ask relevant questions about the intended subject and recipient, ensuring effective communication and saving time on drafting and revising.")
    },
    "travel": {
      "title_en": t("Travel Planning"),
      "long_en": t("Plan the itinerary you want for the season you are traveling in, AI will ask you relevant questions to help discover what makes your trip happy and memorable")
    },
    "genexercise": {
      "title_en": t("Clone Exercise"),
      "long_en": t("Upload sample exercises, adjust difficulty and languages. AI will create new sets of exercises based on your samples.")
    },
    'custrw': {
      "title_en": t("Rewrite with custom AI Instructions"),
      "long_en": t("Instruct AI to rewrite your uploaded file page by page. Download the rewritten file with original formatting")
    },
    "ti": {
      "title_en": t("Generate Image with Text"),
      "long_en": t("Describe what kind of image you want, whether it is photo-realistic or fantasy-abstract, for business or creative purposes, AI will generate for you.")
    },
    "ii": {
      "title_en": t("Edit Image with AI"),
      "long_en": t("You can change the tune, mood, style of an image with AI. Make an image cuter or more serious, vivid or washed out, realistic or abstract. You can also create a new image with a rough sketch, or upsample a dusty old image")
    },
  }
}
  return {
    k
  };
}