import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue';
// const Overview = () => import('./components/Overview.vue');
// const Askai = () => import('./components/Askai.vue');
// const Page500 = () => import('./components/Page500.vue');
// const Page400 = () => import('./components/Page400.vue');
// const Index = () => import('./components/Index.vue');
// const Index = defineAsyncComponent(() => import('./components/Index.vue'))
// const Overview = defineAsyncComponent(() => import('./components/Overview.vue'))
// const Askai = defineAsyncComponent(() => import('./components/Askai.vue'))
// const FileProc = defineAsyncComponent(() => import('./components/FileProc.vue'))

import('./components/Askai.vue') // dynamic loading on all pages with chunking
// import Askai from './components/Askai.vue' //this line would put Askai in the main js

import {loadScript, loadExternalAssets} from './loadScript.js';

const routes = [
  {
    path: '/beta177',
    component: () => import('./components/Home.vue'),
  },
  {
    path: '/login',
    component: () => import('./components/Login.vue'),
  },
  {
    path: '/login.html',
    component: () => import('./components/Login.vue'),
  },
  {
    path: '/register',
    component: () => import('./components/Login.vue'),
  },
  {
    path: '/register.html',
    component: () => import('./components/Login.vue'),
  },
  {
    path: '/faq',
    component: () => import('./components/FAQ.vue'),
  },
  {
    path: '/i/ti',
    component: () => import('./components/TextImage.vue'),
    meta:{
      login_required: true
    }
  },
  {
    path: '/i/ii',
    component: () => import('./components/TextImage.vue'),
    meta: {
      login_required: true
    }
  },
  {
    path: '/accountpage',
    component: () => import('./components/AccountPage.vue'),
  },
  {
    path: '/fileviewer',
    component: () => import('./components/FileViewer.vue'),
  },
  {
    path: `/x/:uuid`,
    component: () => import('./components/Askai.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
    onReady: () => {
        // import(/* @vite-ignore */ './components/Overview.vue');
      },
      parentComponentName: 'Askai'
    }
  },
  {
    path: `/y/:uuid`,
    component: () => import('./components/FileSharedViewer.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
    onReady: () => {
        import(/* @vite-ignore */ './components/Askai.vue');
      },
      parentComponentName: 'FileSharedViewer'
    }
  },
  {
    path: `/z/:uuid`,
    component: () => import('./components/TextImage.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
    onReady: () => {
        import(/* @vite-ignore */ './components/Askai.vue');
      },
      parentComponentName: 'TextImage'
    }
  },
  {
    path: '/addcredit.html',
    component: () => import('./components/Addcredit.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Addcredit'
    }
  },
  {
    path: '/addcredit',
    component: () => import('./components/Addcredit.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Addcredit'
    }
  },
  {
    path: '/page500',
    component: () => import('./components/Page500.vue'),
    beforeEnter: (to, from, next) => {
      next();
    },
    meta: {
      parentComponentName: 'Page500'
    }
  },
  {
    path: '/refresh',
    name: 'refresh',
    component: {
      beforeRouteEnter(to, from, next) {
        next(vm => {
          const { redirect } = to.query
          const { forceRefresh } = to.state || {}
          
          if (forceRefresh) {
            vm.$router.replace({
              path: redirect,
              force: true
            })
          } else {
            vm.$router.replace(redirect)
          }
        })
      }
    }
  },
];

// Generate routes for each base path

const indexPaths = ['/', '/index', '/index.html', ];
indexPaths.forEach(indexPath => {
    routes.push(
      {
        path: indexPath,
        component: () => import('./components/Index.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        // Use meta instead of props
        meta: {
          onReady: () => {
            // this works but no cached
            import(/* @vite-ignore */ './components/Overview.vue');
            import(/* @vite-ignore */ './components/Askai.vue');
            loadExternalAssets();
          },
          parentComponentName: 'Index'
        },
        
      }
    )
  }
)

const oPaths = ['/o', '/overview'];
oPaths.forEach(oPath => {
    routes.push(
      {
        path: oPath,
        component: () => import('./components/Overview.vue'),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          onReady: () => {
            import(/* @vite-ignore */ './components/Askai.vue');
            loadExternalAssets();
          },
          parentComponentName: 'Overview'
        }
      }
    )
  }
)

routes.push(
  {
    path: "/askaispeedtestdemon",
    component: () => import('./components/Askai.vue'),
    beforeEnter: (to, from, next) => {
      next()
    },
    meta: {
    onReady: () => {
        // import(/* @vite-ignore */ './components/Overview.vue');
      },
      parentComponentName: 'Askai'
    }
  },
)

const basePaths = ['/t',  '/v',  '/innerx'];  //'/f',
basePaths.forEach(basePath => {
  routes.push(
    {
      path: `${basePath}/:gptagentkey`,
      component: () => import('./components/Askai.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          // import(/* @vite-ignore */ './components/Overview.vue');
        },
        login_required: true,
        parentComponentName: 'Askai'
      }
    },
    {
      path: `${basePath}/:gptagentkey/:model_select`,
      component: () => import('./components/Askai.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          // import(/* @vite-ignore */ './components/Overview.vue');
        },
        login_required: true,
        parentComponentName: 'Askai'
      }
    }
  );
});

const filePaths = ['/f'];  //'/f',
filePaths.forEach(filePath => {
  routes.push(
    {
      path: `${filePath}/:gptagentkey`,
      component: () => import('./components/FileProc.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          // import(/* @vite-ignore */ './components/Overview.vue');
          // import(/* @vite-ignore */ './components/Askai.vue');
        },
        login_required: true,
        parentComponentName: 'FileProc'
      }
    },
    {
      path: `${filePath}/:gptagentkey/:model_select`,
      component: () => import('./components/FileProc.vue'),
      beforeEnter: (to, from, next) => {
        next()
      },
      meta: {
      onReady: () => {
          // import(/* @vite-ignore */ './components/Overview.vue');
          // import(/* @vite-ignore */ './components/Askai.vue');
        },
        login_required: true,
        parentComponentName: 'FileProc'
      }
    }
  );
});

// Error handling routes
routes.push({ path: '/:pathMatch(.*)*', component: () => import('./components/Page400.vue') });
routes.push({ path: '/error', component: () => import('./components/Page500.vue') });

// Create and export the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;