<template>
    <div class="modal fade" id="loginRegisterModal" tabindex="-1" aria-labelledby="loginRegisterModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div class="modal-content bg-white">
          <div class="modal-header text-dark">
            <h5 class="modal-title" id="loginRegisterModalLabel">{{ $t("Please Login or Register to Continue. New Accounts has Free Trial") }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-dark">
            <div class="container-fluid">
              <div class="row">
                <!-- Left pane (Login) -->
                <div class="col-lg-6" :class="{ 'p-3': gstate.is_lg }">
                  <div class="text-center mb-3">
                    <h3 class="h4">{{ $t("Login / Sign Up with Google") }}</h3>
                    
                  </div>
                  
                  <!-- Google Login Button -->
                  <div class="d-grid mb-3">
                    <a class="btn" href="/googlelogin/google">
                      <img src="/static/assets/img/googlelogin.png" alt="Login with Google">
                    </a>
                  </div>
                  <div :class='{"bg-gray-400": !gstate.is_lg}'>
                    <div class="text-center mb-2 h4">{{ $t("Login to Existing Account") }}</div>
                    <form @submit.prevent="submitLogin">
                        <div class="form-group mb-3">
                        <label for="login-email">{{ $t("Email") }}</label>
                        <div class="input-group">
                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                            <input 
                            type="email" 
                            class="form-control" 
                            id="login-email" 
                            v-model="rlstate.loginEmail"
                            required
                            >
                        </div>
                        </div>
                        
                        <div class="form-group mb-3">
                        <label for="login-password">{{ $t("Password") }}</label>
                        <div class="input-group">
                            <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                            <input 
                            type="password" 
                            class="form-control" 
                            id="login-password" 
                            v-model="rlstate.loginPassword"
                            required
                            >
                        </div>
                        </div>
                        <span class="font-weight-normal text-danger" v-show="rlstate.loginMsg !== ''">
                            {{ rlstate.loginMsg }}
                        </span>
                        <span class="font-weight-normal text-danger" v-show="rlstate.loginStatus === 'unconfirmed'">
                            {{$t("You have not yet completed the registration process. Please verify your email by clicking on the links we sent to your email.")}} 
                            <br> {{$t("If you have lost/not received our email please click")}} <a class="badge bg-warning text-dark" href='/reset.html'>{{$t("Reset Password")}}</a>
                        </span>
                        <div class="row justify-content-center">
                        <button type="submit" class="col-4 btn btn-tertiary me-4"
                        
                        >
                        <!-- :class="{'me-4': gstate.is_lg}" -->
                            {{ $t("Sign in") }}
                        </button>
                        <a href="/reset" class="col-4 btn btn-outline-primary">{{ $t("Reset Password") }}</a>
                        </div>
                    </form>
                  </div>
                  
                </div>
                
                <!-- Right pane (Register) -->
                <div class="col-lg-6" :class="{ 'p-3': gstate.is_lg }" v-show="rlstate.registerSuccess">
                    <span class="text-success">
                        {{ rlstate.registerMsg }}
                    </span>
                </div>
                <div class="col-lg-6" :class="{ 'p-3': gstate.is_lg }" v-show="!rlstate.registerSuccess">
                  <div class="text-center mb-3">
                    <h3 class="h4">{{ $t("Register an account") }}</h3>
                    
                    <!-- || $t("Fill in your information and verify your email")  -->
                  </div>
                  
                  <!-- Register Form -->
                  <form @submit.prevent="submitRegister">
                    <div class="form-group mb-3">
                      <label for="register-email">{{ $t("Email") }}</label>
                      <div class="input-group">
                        <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                        <input 
                          type="email" 
                          class="form-control" 
                          id="register-email" 
                          v-model="rlstate.registerEmail"
                          @input="checkEmail"
                          required
                        >
                      </div>
                      <div class="text-primary small" v-if="rlstate.emailMessage" v-html="rlstate.emailMessage"></div>
                    </div>
                    
                    <div class="row mb-3">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="register-password">{{ $t("Password") }}</label>
                          <div class="input-group">
                            <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                            <input 
                              type="password" 
                              class="form-control" 
                              id="register-password" 
                              v-model="rlstate.registerPassword"
                              @input="checkPasswords"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="register-password2">{{ $t("Enter Password Again") }}</label>
                          <div class="input-group">
                            <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                            <input 
                              type="password" 
                              class="form-control" 
                              id="register-password2" 
                              v-model="rlstate.registerPassword2"
                              @input="checkPasswords"
                              required
                            >
                          </div>
                        </div>
                      </div>
                      <div class="text-danger small mt-1" v-if="rlstate.passwordError">{{ rlstate.passwordError }}</div>
                    </div>
                    <div class="form-group mb-3">
                      <div class="d-flex justify-content-between mb-2">
                        <a href="#" @click.prevent="refreshCaptcha">
                          {{ $t("Click to change captcha") }} <i class="fas fa-rotate-right"></i>
                        </a>
                      </div>
                      <div class="captcha-container mb-2">
                        <!-- v-html="rlstate.captchaHtml" -->
                        <img class="simple-captcha-img" @click="refreshCaptcha" :src="'data:image/png;base64,' + rlstate.captcha_img" alt="Captcha" style="cursor: pointer;">
                        <input type="text" class="simple-captcha-text ms-4" id="captcha-text" name="captcha-text" v-model="rlstate.captchaText"
                        @input="validateCaptchaOnType"
                        :disabled="rlstate.captchaVerified"
                        >
                        <!-- show a green tick if captchaVerified -->
                        <i class="fas fa-check-circle text-success" v-show="rlstate.captchaVerified"></i>
                        <div class="text-danger small" v-if="rlstate.captchaError">{{ rlstate.captchaError }}</div>
                        <input type="hidden" name="captcha-hash" v-model="rlstate.captcha_hash">
                    </div>
                      
                    </div>
                    
                    <div class="form-check mb-1">
                      <input 
                        class="form-check-input" 
                        type="checkbox" 
                        id="agree-terms" 
                        checked
                        disabled
                      >
                      <label class="form-check-label" for="agree-terms">
                        {{ $t("I agree to the") }} <a href="/tos" class="fw-bold">{{ $t("Terms") }}</a>
                      </label>
                    </div>
                    <span class="font-weight-normal text-danger mb-2 mt-1" v-if="rlstate.registerMsg !== ''">
                      {{ rlstate.registerMsg}}
                    </span>
                    <div class="row justify-content-center">
                        <div class="d-grid col-4">
                        <button 
                            type="submit" 
                            class="btn btn-tertiary"
                            :disabled="!canRegister"
                        >
                            {{ $t("Sign Up") }}
                        </button>
                        </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {createApp, ref, reactive, watch, inject, provide, computed, onMounted, onUnmounted, watchEffect, defineAsyncComponent} from 'vue';
  import { useI18n } from 'vue-i18n'
  
  export default {
    name: 'LoginRegister',
    setup() {
      const { t } = useI18n()
      const gstate = inject('gstate')
      // Reactive state for all form variables
      const rlstate = reactive({
        // Login form
        loginEmail: '',
        loginPassword: '',
        rememberMe: true,
        loginMsg: '',
        
        // Register form
        registerEmail: '',
        registerPassword: '',
        registerPassword2: '',
        referralCode: '',
        captchaHtml: '',
        captchaText: '',
        captchaHash: '',
        agreeTerms: true,
        
        // Messages and errors
        registerMsg: '',
        emailMessage: '',
        passwordError: '',
        captchaVerified: false,
        referralMessage: '',
        captchaError: '',
        captcha_img: '',
        captcha_hash: '',
        captcha_text: '',
        // Success states
        loginSuccess: false,
        registerSuccess: false,

      })
      
      // Get referral from cookie if available
      const validateCaptcha = async () => {
        if (rlstate.captchaText.length < 6 || rlstate.captchaVerified) return;
        
        try {
            const response = await fetch('/_validate_captcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'captcha-hash': rlstate.captcha_hash,
                'captcha-text': rlstate.captchaText
            })
            });
            
            const data = await response.json();
            
            if (data.status === 'success') {
            rlstate.captchaVerified = true;
            rlstate.captchaError = '';
            } else {
            rlstate.captchaVerified = false;
            rlstate.captchaError = t('Invalid Captcha');
            }
        } catch (error) {
            console.error('Error validating captcha:', error);
            rlstate.captchaError = t('Error validating captcha');
        }
        };
        
        // Function to validate captcha when typing
        const validateCaptchaOnType = () => {
        rlstate.captchaError = '';
        if (rlstate.captchaText.length === 6) {
            validateCaptcha();
        }
    };
      const getReferralFromCookie = () => {
        const name = "referral=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
      
      // Check if passwords match
      const checkPasswords = () => {
        if (rlstate.registerPassword && rlstate.registerPassword2) {
          if (rlstate.registerPassword !== rlstate.registerPassword2) {
            rlstate.passwordError = t('The two passwords do not match');
          } else if (rlstate.registerPassword.length < 8) {
            rlstate.passwordError = t('Password must be at least 8 characters long');
          } else {
            rlstate.passwordError = '';
          }
        }
      }
      
      // Check email with backend API
      const checkEmail = async () => {
        if (!rlstate.registerEmail) return;
        
        try {
          const response = await fetch('/_checkemail', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ proposed_email: rlstate.registerEmail })
          });
          
          const data = await response.json();
          rlstate.emailMessage = data.proposed_email_msg;
        } catch (error) {
          console.error('Error checking email:', error);
        }
      }
      
      // Check referral with backend API
      const checkReferral = async () => {
        if (!rlstate.referralCode) return;
        
        try {
          const response = await fetch('/_checkreferral', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ in_referral: rlstate.referralCode })
          });
          
          const data = await response.json();
          rlstate.referralMessage = data.referralmsg;
        } catch (error) {
          console.error('Error checking referral:', error);
        }
      }
      
      const refreshCaptcha = async () => {
        if (rlstate.captchaVerified) {
            console.log("Captcha already verified, not refreshing");
            return;
        }
        try {
            const response = await fetch('/_refresh_captcha', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ refresh: "captcha" })
            });
            
            const data = await response.json();
            const imgSrcMatch = data.captcha.match(/src="data:image\/[^;]+;base64,([^"]+)"/);
            if (imgSrcMatch && imgSrcMatch[1]) {
                rlstate.captcha_img = imgSrcMatch[1];
            }
            
            // Parse the captcha hash from the HTML if it exists
            const hashMatch = data.captcha.match(/name="captcha-hash" value="([^"]+)"/);
            if (hashMatch && hashMatch[1]) {
                rlstate.captcha_hash = hashMatch[1];
            }
            
            // You can still keep the full HTML if needed
            rlstate.captchaHtml = data.captcha;
        } catch (error) {
            console.error("Error refreshing captcha:", error);
        }
    };
      
      // Login form submission
    //   const submitLogin = async () => {
    //     try {
    //       const response = await fetch('/login', {
    //         method: 'POST',
    //         headers: {
    //           'Content-Type': 'application/x-www-form-urlencoded'
    //         },
    //         body: new URLSearchParams({
    //           'email': rlstate.loginEmail,
    //           'password': rlstate.loginPassword,
    //           'remember_me': rlstate.rememberMe
    //         })
    //       });
    //       console.log("response", response)
    //       if (response.redirected) {
    //         // Successful login, follow the redirect
    //         window.location.href = response.url;
    //       } else {
    //         const html = await response.text();
    //         const parser = new DOMParser();
    //         const doc = parser.parseFromString(html, 'text/html');
    //         const errorMsg = doc.querySelector('.text-center .font-weight-normal');
    //         if (errorMsg) {
    //           rlstate.loginMsg = errorMsg.innerHTML;
    //         } else {
    //           rlstate.loginMsg = t('Login failed. Please try again.');
    //         }
    //       }
    //     } catch (error) {
    //       console.error('Error during login:', error);
    //       rlstate.loginMsg = t('An error occurred during login. Please try again.');
    //     }
    //   }
        const submitLogin = async () => {
            try {
                const response = await fetch('/_login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        'email': rlstate.loginEmail,
                        'password': rlstate.loginPassword,
                        'remember_me': rlstate.rememberMe
                    })
                });
                
                const data = await response.json();
                
                if (data.success) {
                    // Successful login, redirect to the specified URL
                    window.location.href = gstate.rlstate.redirect//data.redirect;
                } else {
                    // Display the error message
                    rlstate.loginMsg = data.message;
                }
                if (data.status) {
                    rlstate.loginStatus = data.status;
                }
            } catch (error) {
                console.error('Error during login:', error);
                rlstate.loginMsg = t('An error occurred during login. Please try again.');
            }
        }
      
      const submitRegister = async () => {
        if (!canRegister.value) return;
        
        try {
            const response = await fetch('/_register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'email': rlstate.registerEmail,
                'password': rlstate.registerPassword,
                'password2': rlstate.registerPassword2,
                'referral': rlstate.referralCode,
                'captcha-hash': rlstate.captcha_hash,
                'captcha-text': rlstate.captchaText
            })
            });
            
            const data = await response.json();
            
            if (data.success) {
            rlstate.registerMsg = data.message;
            rlstate.registerSuccess = true;
            
            // Clear the form
            rlstate.registerEmail = '';
            rlstate.registerPassword = '';
            rlstate.registerPassword2 = '';
            rlstate.captchaText = '';
            rlstate.captchaVerified = false;
            refreshCaptcha();
            } else {
            rlstate.registerMsg = data.message;
            rlstate.registerSuccess = false;
            
            // If captcha was incorrect, refresh it
            if (data.captcha_error) {
                refreshCaptcha();
            }
            }
        } catch (error) {
            console.error('Error during registration:', error);
            rlstate.registerMsg = t('An error occurred during registration. Please try again.');
            rlstate.registerSuccess = false;
        }
        }

      const canRegister = computed(() => {
        // Only enable registration when all fields are valid and captcha is verified
        return rlstate.registerEmail && 
            rlstate.registerPassword && 
            rlstate.registerPassword2 && 
            !rlstate.passwordError &&
            rlstate.captchaVerified
        });
      
      // On component mount
      onMounted(async () => {
        console.log("RL mounted!")
        await refreshCaptcha();
        
        // Check for referral in cookie
        const referral = getReferralFromCookie();
        if (referral) {
          rlstate.referralCode = referral;
          await checkReferral();
        }
        
        
        // After the component is mounted, we need to attach click event to the captcha image
        // This needs to be done after the captcha HTML is loaded
        setTimeout(() => {
          const captchaImg = document.querySelector('.simple-captcha-img');
          if (captchaImg) {
            captchaImg.addEventListener('click', refreshCaptcha);
          }
        }, 500);
      });
      
      return {
        rlstate,
        checkPasswords,
        checkEmail,
        checkReferral,
        refreshCaptcha,
        submitLogin,
        submitRegister,
        canRegister, validateCaptcha, validateCaptchaOnType,
        t, gstate,
      }
    }
  }
  </script>
  
  <style scoped>
  .captcha-container {
    cursor: pointer;
  }
  
  .modal-body {
    max-height: 80vh;
  }
  
  @media (max-width: 991.98px) {
    .col-lg-6:first-child {
      margin-bottom: 2rem;
    }
  }
  </style>